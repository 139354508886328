@tailwind base;
@tailwind components;
@tailwind utilities; 

@import url('https://fonts.cdnfonts.com/css/jetbrains-mono-2');
@import 'intro.js/minified/introjs.min.css';

body {
  margin: 0;  
  font-family: "JetBrains Mono", system-ui, monospace;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #141313;
  color: #fdf7f1;	
  background-size: 50px 50px;
  background-position: 0 0, 25px 25px;
}

.animations-enabled {
	background-image: radial-gradient(hsla(304, 100%, 51%, 0.811) 1px, transparent 0),
	radial-gradient(hsla(48, 100%, 51%, 0.791) 1px, transparent 0);
	-webkit-animation: slide 2s linear infinite;
  animation: slide 4s linear infinite;
}

@keyframes slide {
	100% {
		background-position: 50px 0, 125px 25px;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* Custom IntroJS style stuff */
.introjs-tooltip {
  background: #1a1a1a;
  color: #ff10f0;
  border: 2px solid #ff10f0;
  box-shadow: 0 0 10px rgba(255, 16, 240, 0.3);
}

.introjs-tooltiptext {
  color: #ff10f0;
}

.introjs-button {
  background: #2d1f3d;
  color: #ff10f0;
  border: 1px solid #ff10f0;
  text-shadow: none;
  transition: all 0.3s ease;
}

.introjs-button:hover {
  background: #ff10f0;
  color: #1a1a1a;
}

.introjs-helperLayer {
  background: rgba(255, 16, 240, 0.1);
  border: 2px solid #ff10f0;
  box-shadow: 0 0 10px rgba(255, 16, 240, 0.3);
}

.introjs-arrow {
  border-color: #ff10f0;
}

.introjs-arrow.top {
  border-bottom-color: #1a1a1a;
}

.introjs-arrow.right {
  border-left-color: #1a1a1a;
}

.introjs-arrow.bottom {
  border-top-color: #1a1a1a;
}

.introjs-arrow.left {
  border-right-color: #1a1a1a;
}